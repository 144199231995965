import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import is from '@sindresorhus/is';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { setFieldError, updateField } from '@ui/features/configurator/store';
import type { ConfiguratorNumberType } from '@ui/features/configurator/types';
import { memo } from 'react';
import { ConfiguratorInputIcon } from './InputIcon';
import { ConfiguratorInputWrapper } from './InputWrapper';
import { inputStyles } from './styles';
import type { ConfiguratorInputProps } from './types';

function NumberInputComponent({ fieldKey, ...widths }: ConfiguratorInputProps) {
  const { fields, dispatch } = useConfiguratorStore();

  const field = fields[fieldKey];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      dispatch(updateField(fieldKey, null));
    } else if (!is.numericString(e.target.value)) {
      dispatch(setFieldError(fieldKey, 'Must be a number'));
    } else {
      dispatch(updateField(fieldKey, Number(e.target.value)));
    }
  };

  return (
    <ConfiguratorInputWrapper fieldKey={fieldKey} {...widths}>
      <InputGroup>
        {field.leftIcon && (
          <InputLeftElement pointerEvents="none">
            <ConfiguratorInputIcon icon={field.leftIcon} />
          </InputLeftElement>
        )}

        <Input
          id={fieldKey}
          type="number"
          onChange={handleChange}
          value={(field.value as ConfiguratorNumberType) ?? ''}
          placeholder={field.placeholder}
          {...inputStyles}
        />

        {field.rightIcon && (
          <InputRightElement pointerEvents="none">
            <ConfiguratorInputIcon icon={field.rightIcon} />
          </InputRightElement>
        )}
      </InputGroup>
    </ConfiguratorInputWrapper>
  );
}

/**
 * Number input for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorNumberInput = memo(NumberInputComponent);
