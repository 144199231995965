import type { Configuration } from '@ui/features/configurator/types';
import {
  COMPUTED_FIELDS_FIELD_IDS,
  COMPUTED_FIELDS_STEP_KEYS,
  defaultComputedFieldsStep,
} from '../constants/default-configuration/computed';
import {
  EXTERNAL_OPERATION_FIELD_IDS,
  EXTERNAL_OPERATION_STEP_KEYS,
  defaultExternalServiceStep,
} from '../constants/default-configuration/external-operations';
import {
  INTERNAL_OPERATION_FIELD_IDS,
  defaultInternalOperationFields,
} from '../constants/default-configuration/internal-operation-fields';
import { OPERATION_FIELD_IDS } from '../constants/default-configuration/operations';
import {
  PREREQUISITE_FIELD_IDS,
  PREREQUISITE_STEP_KEYS,
  customerPartIdField,
  defaultBaseFieldsStep,
  defaultDescriptionStep,
  defaultMaterialStep,
  productCodeField,
  quantityField,
} from '../constants/default-configuration/prerequisites';

import {
  VOLUME_CALCULATIONS_FIELD_IDS,
  VOLUME_CALCULATIONS_STEP_KEYS,
  defaultVolumeStep,
} from '../constants/default-configuration/volume-calculations';

export const STEP_KEYS = {
  ...PREREQUISITE_STEP_KEYS,
  ...VOLUME_CALCULATIONS_STEP_KEYS,
  ...EXTERNAL_OPERATION_STEP_KEYS,
  ...COMPUTED_FIELDS_STEP_KEYS,
  head_style: 'head_style',
  shank: 'shank',
  threading: 'threading',
  sems: 'sems',
  heading: 'heading',
  rolling_threading: 'rolling_threading',
  pointing: 'pointing',
  packing: 'packing',
  packaging: 'packaging',
  sorting: 'sorting',
  serrations: 'serrations',
} as const;

export const FIELD_IDS = {
  ...PREREQUISITE_FIELD_IDS,
  ...VOLUME_CALCULATIONS_FIELD_IDS,
  ...EXTERNAL_OPERATION_FIELD_IDS,
  ...COMPUTED_FIELDS_FIELD_IDS,
  ...INTERNAL_OPERATION_FIELD_IDS,
  ...OPERATION_FIELD_IDS,
  thread_type: 'thread_type',
  thread_diameter: 'thread_diameter',
  threads_per_inch: 'threads_per_inch',
  add_sems: 'add_sems',
  washer_style: 'washer_style',
  washer_size: 'washer_size',
  washer_material: 'washer_material',
  washer_material_cost: 'washer_material_cost',
  head_style: 'head_style',
  head_slot_style: 'head_slot_style',
  head_recess_style: 'head_recess_style',
  shank_length: 'shank_length',
  shank_length_fractional: 'shank_length_fractional',
  shank_shoulder: 'shank_shoulder',
  point_style: 'point_style',
  serration_type: 'serration_type',
} as const;

/**
 * @INCOMPLETE(shawk): We should try to validate decisions before doing a full
 * pass and attempting to consolidate all the information in the table here:
 * https://www.notion.so/viget/Business-Requirements-10a03cb729ed4153b6b1eaf0bb1dba7a
 */
export const coldHeadedFastenerConfiguration = {
  id: 'bac8764f-90ac-4534-b363-580a7e98d050',
  name: 'Cold Headed Fastener',
  steps: [
    {
      ...defaultBaseFieldsStep,
      fields: [
        {
          ...quantityField,
          description: 'Quantity in thousands',
        },
        productCodeField,
        customerPartIdField,
      ],
    },
    defaultDescriptionStep,
    defaultMaterialStep,
    defaultVolumeStep,
    {
      id: 'CF842858-12F7-4BE3-801B-FAD02591714F',
      step_key: STEP_KEYS.threading,
      name: 'Threading',
      step_type: 'prerequisite',
      fields: [
        {
          name: 'Thread Type',
          field_id: FIELD_IDS.thread_type,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'thread_type',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [],
          dependency_mode: null,
        },
        {
          name: 'Thread Diameter',
          field_id: FIELD_IDS.thread_diameter,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'thread_diameter',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [
            {
              step_id: null,
              field_id: 'thread_type',
              dependency_type: 'simple',
            },
          ],
          dependency_mode: 'enable',
          clear_on_noninteractive: true,
        },
        {
          name: 'Threads Per Inch',
          field_id: FIELD_IDS.threads_per_inch,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'threads_per_inch',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [
            {
              step_id: null,
              field_id: 'thread_diameter',
              dependency_type: 'simple',
            },
          ],
          dependency_mode: 'enable',
          clear_on_noninteractive: true,
        },
      ],
    },
    {
      id: '17AE7AE8-DF20-42F1-8DFD-7A13D58E9DE7',
      step_key: STEP_KEYS.head_style,
      name: 'Head Style',
      step_type: 'prerequisite',
      fields: [
        {
          name: 'Head Style',
          field_id: FIELD_IDS.head_style,
          input_type: 'single_choice',
          description: 'The Head Style is the style of the Heading',
          input_source: {
            source_type: 'static',
            data_key: 'head_style',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [],
          dependency_mode: null,
        },
        {
          name: 'Head Slot Style',
          field_id: FIELD_IDS.head_slot_style,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'head_slot_style',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [],
          dependency_mode: null,
        },
        {
          name: 'Head Recess Style',
          field_id: FIELD_IDS.head_recess_style,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'head_recess_style',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [],
          dependency_mode: null,
        },
      ],
    },
    {
      id: 'FFAB3BF8-8405-49F5-B20A-E0DAF306BF15',
      step_key: STEP_KEYS.shank,
      name: 'Shank',
      step_type: 'prerequisite',
      fields: [
        {
          name: 'Shank Length',
          field_id: FIELD_IDS.shank_length,
          input_type: 'number',
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [],
          dependency_mode: null,
        },
        {
          name: 'Shank Length (Fractional)',
          field_id: FIELD_IDS.shank_length_fractional,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'shank_length',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [],
          dependency_mode: null,
        },
        {
          name: 'Shank Shoulder',
          field_id: FIELD_IDS.shank_shoulder,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'shank_shoulder',
          },
          validations: [],
          dependencies: [],
          dependency_mode: null,
        },
      ],
    },
    {
      id: 'E9F7FED6-F8BC-4753-833C-37DB98D8E49C',
      step_key: STEP_KEYS.sems,
      name: 'SEMS',
      step_type: 'prerequisite',
      fields: [
        {
          name: 'Add SEMS',
          field_id: FIELD_IDS.add_sems,
          input_type: 'boolean',
          validations: [],
          dependencies: [],
          dependency_mode: null,
        },
        {
          name: 'Washer Style',
          field_id: FIELD_IDS.washer_style,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'washer_style',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [
            {
              step_id: null,
              field_id: 'add_sems',
              dependency_type: 'boolean',
              boolean_value: true,
            },
          ],
          dependency_mode: 'show',
          clear_on_noninteractive: true,
        },
        {
          name: 'Washer Size',
          field_id: FIELD_IDS.washer_size,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'washer_size',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [
            {
              step_id: null,
              field_id: 'add_sems',
              dependency_type: 'boolean',
              boolean_value: true,
            },
          ],
          dependency_mode: 'show',
          clear_on_noninteractive: true,
        },
        {
          name: 'Washer Material',
          field_id: FIELD_IDS.washer_material,
          input_type: 'material',
          input_source: {
            source_type: 'dynamic',
            source_key: 'washer_material',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [
            {
              step_id: null,
              field_id: 'add_sems',
              dependency_type: 'boolean',
              boolean_value: true,
            },
          ],
          dependency_mode: 'show',
          clear_on_noninteractive: true,
        },
        {
          name: 'Washer Material Cost',
          field_id: FIELD_IDS.washer_material_cost,
          input_type: 'number',
          left_icon: 'dollar',
          validations: [
            {
              validate: 'required',
              allow_zero: false,
            },
          ],
          dependencies: [
            {
              step_id: null,
              field_id: 'add_sems',
              dependency_type: 'boolean',
              boolean_value: true,
            },
          ],
          dependency_mode: 'show',
          clear_on_noninteractive: true,
        },
      ],
    },
    {
      id: '4A2ADBC0-AB66-4090-829A-48277D62E4EC',
      step_key: STEP_KEYS.heading,
      name: 'Heading',
      step_type: 'operation',
      operation_type: 'internal',
      required: true,
      reusable: false,
      description:
        'The Heading operation determines the attributes of the fastener head',
      fields: defaultInternalOperationFields,
    },
    {
      id: 'F07A1363-386F-4125-961F-75A790B40BD0',
      step_key: STEP_KEYS.rolling_threading,
      name: 'Rolling/Threading',
      step_type: 'operation',
      operation_type: 'internal',
      required: false,
      reusable: true,
      fields: defaultInternalOperationFields,
    },
    {
      id: '95FAFE80-0517-48BB-AB09-1CF208B02D12',
      step_key: STEP_KEYS.pointing,
      name: 'Pointing',
      step_type: 'operation',
      operation_type: 'internal',
      required: false,
      reusable: true,
      fields: [
        {
          name: 'Point Style',
          field_id: FIELD_IDS.point_style,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'point_style',
          },
          validations: [
            {
              validate: 'required',
            },
          ],
          dependencies: [],
          dependency_mode: null,
        },
        ...defaultInternalOperationFields,
      ],
    },
    {
      id: '2C609DA3-DB0A-4465-88A2-9CFE9A2FEF90',
      step_key: STEP_KEYS.packing,
      name: 'Packing',
      step_type: 'operation',
      operation_type: 'internal',
      required: false,
      reusable: true,
      fields: defaultInternalOperationFields,
    },
    {
      id: 'A743D20D-7E28-49FB-B640-92E00AC1FD08',
      step_key: STEP_KEYS.packaging,
      name: 'Packaging',
      step_type: 'operation',
      operation_type: 'internal',
      required: false,
      reusable: true,
      fields: defaultInternalOperationFields,
    },
    {
      id: '8BDEA7B2-B3D8-42D0-946E-2D968300291C',
      step_key: STEP_KEYS.sorting,
      name: 'Sorting',
      step_type: 'operation',
      operation_type: 'internal',
      required: false,
      reusable: true,
      fields: defaultInternalOperationFields,
    },
    {
      id: 'E1CB24E8-60FE-4838-A0DA-F5FB5D0B5EAD',
      step_key: STEP_KEYS.serrations,
      name: 'Serrations',
      step_type: 'operation',
      operation_type: 'internal',
      required: false,
      reusable: true,
      fields: [
        {
          name: 'Serration Type',
          field_id: FIELD_IDS.serration_type,
          input_type: 'single_choice',
          input_source: {
            source_type: 'static',
            data_key: 'serration_type',
          },
          validations: [{ validate: 'required' }],
          dependencies: [],
          dependency_mode: null,
        },
        ...defaultInternalOperationFields,
      ],
    },
    defaultExternalServiceStep,
    // defaultPurchaseMaterialStep,
    // defaultFabricatedMaterialStep,
    // defaultLaborCostStep,
    // defaultBurdenCostStep,
    // defaultServiceStep,
    defaultComputedFieldsStep,
  ],
} satisfies Configuration;

/**
 * DEVELOPMENT ONLY (if HMR is enabled)
 *
 * Propagate HMR updates to importers (allows for patching the configurator
 * store with updated static configuration data).
 *
 * Ref: https://vitejs.dev/guide/api-hmr
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    import.meta.hot?.invalidate();
  });
}
