import { hasPermissions } from '@lib/permissions/permissions';
import type { PermissionsReqInput } from '@lib/requests/permissions';
import ErrorUnauthorized from '@ui/components/Errors/ErrorUnauthorized';
import { useAuth } from '@ui/state/auth';

type WithUserType = {
  children: React.ReactNode;
  permissions: PermissionsReqInput;
  page?: boolean;
};

export const WithPermission = ({
  children,
  permissions,
  page,
}: WithUserType) => {
  const { user } = useAuth();

  if (!Object.keys(permissions).length) {
    return <>{children}</>;
  }

  if (!user) {
    return null;
  }

  if (hasPermissions(permissions, user)) {
    return <>{children}</>;
  }

  return page ? (
    <>
      <ErrorUnauthorized />
    </>
  ) : null;
};
