import {
  Show as ChakraShow,
  type ShowProps as ChakraShowProps,
  useToken,
} from '@chakra-ui/react';
import type { Breakpoints } from '@ui/config/theme';

// The original Chakra types allow you to pass any combination of `above`, `below` and
// `breakpoint`, but will only respect one option. This can create confusion and is
// just generally a bad idea. This type forces you to use only one option at a time.
type DiscriminatedShowOptions =
  | {
      // biome-ignore lint/suspicious/noExplicitAny: Takes any value; returns children if the value is truthy
      when?: any;
      above?: never;
      below?: never;
      breakpoint?: never;
    }
  | {
      when?: never;
      above?: Breakpoints;
      below?: never;
      breakpoint?: never;
    }
  | {
      when?: never;
      above?: never;
      below?: Breakpoints;
      breakpoint?: never;
    }
  | {
      when?: never;
      above?: never;
      below?: never;
      breakpoint?: string;
    };

type ShowProps = Pick<ChakraShowProps, 'children' | 'ssr'> &
  DiscriminatedShowOptions;

/**
 * Used to conditionally render children based on a truthy value or a media query.
 *
 * Only one of `when`, `above`, `below`, or `breakpoint` can be provided.
 *
 * @see Docs https://chakra-ui.com/docs/components/show-hide
 */
export const Show: React.FC<ShowProps> = ({
  when,
  children,
  above,
  below,
  breakpoint,
  ssr,
}) => {
  const [breakpointValue] = useToken('breakpoints', [above ?? below ?? '']);

  if (when) {
    return <>{children}</>;
  }

  if (above && breakpointValue) {
    return (
      <ChakraShow breakpoint={`(min-width: ${breakpointValue})`} ssr={ssr}>
        {children}
      </ChakraShow>
    );
  }

  if (below && breakpointValue) {
    return (
      <ChakraShow
        breakpoint={`(max-width: calc(${breakpointValue} - 1px))`}
        ssr={ssr}
      >
        {children}
      </ChakraShow>
    );
  }

  if (breakpoint) {
    return (
      <ChakraShow breakpoint={breakpoint} ssr={ssr}>
        {children}
      </ChakraShow>
    );
  }

  return null;
};
