import type { ResolvedOpCostsInput } from '@lib/calculations/part-history';
import {
  calculateOperationInputQty,
  maybeDivide,
  maybeMultiply,
  maybeParseNum,
  round,
} from '@lib/calculations/util';
import type { Nullable } from '@lib/util';
import { ScrapYieldType } from '@prisma/client';
import { omit } from 'lodash-es';
import { orderBy } from 'lodash-es';
import { type AggCostCalculations, calcCostAggs } from './aggs';

const _round = <T extends null | undefined>(
  val: number | T,
  { min = 2, small = 3 } = {},
): number | T => {
  if (val == null) return val;
  return round(val, { min, small });
};

// TODO:(bb) clean up typing
export interface OpCostCalcsMemo extends Nullable<AggCostCalculations> {
  resolvedOpCosts: ResolvedOpCostsInput[];
}

export const operationCostCalcs = (
  costInputs: ResolvedOpCostsInput[],
  quantity: string | number,
): OpCostCalcsMemo => {
  // inputQty is a rolling total to calculate scrap back from the final operation output
  // 1. reverse the op inputs array
  // 2. starting from the final operation and desired lineQty,
  //    map the ops and compute the inputQty from the scrap and desired output qty
  // 3. reverse the output array to get the correct order again

  let inputQty: number | null;

  // for calculating input units via scrap from the last op back

  const sortedOpsBySequenceNumReversed = orderBy(
    costInputs,
    'sequenceNum',
    'desc',
  );

  // recalc cost qty's
  const costsWithUpdQty = sortedOpsBySequenceNumReversed
    .map((opCostInput): ResolvedOpCostsInput => {
      const {
        calcQty: _,
        calcRunHrs: __,
        requirements,
        scrapFixedUnits,
        scrapYieldType,
        scrapYieldPercent,
        ...restOpCostInput
      } = opCostInput;
      const { qtyPerRunHr } = opCostInput;
      const lineQty = maybeParseNum(quantity);

      let percentYield = 1;
      if (scrapYieldPercent && scrapYieldType === ScrapYieldType.SCRAP) {
        percentYield = 1 - scrapYieldPercent / 100;
      }
      if (scrapYieldPercent && scrapYieldType === ScrapYieldType.YIELD) {
        percentYield = scrapYieldPercent / 100;
      }

      // the output qty of an operation is equivalent to the inputQty of the next operation
      // if inputQty is null (final operation), the qty is the desired lineQty
      const updCalcQty = inputQty ? inputQty : lineQty ?? null;
      const calcStartQty =
        calculateOperationInputQty(
          updCalcQty,
          scrapFixedUnits,
          scrapYieldType,
          scrapYieldPercent,
        ) ?? null;
      inputQty = calcStartQty;

      const updCalcRunHrs =
        qtyPerRunHr === 0
          ? 0
          : _round(maybeDivide(updCalcQty, qtyPerRunHr) ?? null, {
              small: 2,
            });
      const updReqs = requirements?.map((req) => {
        const restReq = omit(req, ['calcQtyFromLine', 'calcQtyFromOp']);
        const { qtyPerLineQty, unitsPerPiece, fixedQty } = req;

        //TODO: figure out unitsPerPieceType and how it affects this calculation
        let calcQtyFromOp = maybeMultiply(inputQty, unitsPerPiece) ?? null;
        // requirements also have a "fixed qty" that is always included
        if (calcQtyFromOp && fixedQty) {
          calcQtyFromOp += fixedQty;
        }

        return {
          ...restReq,
          calcQtyFromLine: _round(
            maybeMultiply(lineQty, qtyPerLineQty) ?? null,
          ),
          calcQtyFromOp,
        };
      });

      return {
        ...restOpCostInput,
        calcQty: updCalcQty,
        calcRunHrs: updCalcRunHrs,
        requirements: updReqs,
        scrapFixedUnits,
        scrapYieldPercent,
        scrapYieldType,
        calcStartQty,
      };
    })
    .toReversed();
  const { agg = null, nested = null } = calcCostAggs(costsWithUpdQty) ?? {};

  const ret = {
    agg,
    nested,
    resolvedOpCosts: costsWithUpdQty,
  };

  return ret;
};
