import { maybeParseNum, round } from '../util';

type CalculateFromMarkupInput = {
  quantity?: number | string | null;
  unitMaterialCost?: number | string | null;
  unitLaborCost?: number | string | null;
  unitBurdenCost?: number | string | null;
  unitServiceCost?: number | string | null;
  purchaseMaterialAmount?: number | string | null;
  purchaseMaterialPercent?: number | string | null;
  fabricatedMaterialAmount?: number | string | null;
  fabricatedMaterialPercent?: number | string | null;
  laborCostAmount?: number | string | null;
  laborCostPercent?: number | string | null;
  burdenCostAmount?: number | string | null;
  burdenCostPercent?: number | string | null;
  serviceCostAmount?: number | string | null;
  serviceCostPercent?: number | string | null;
};

function calculateAdjustment(
  initial: number,
  percent: number,
  amount: number,
  quantity: number,
) {
  return initial * (percent / 100) + amount / quantity;
}

/**
 * Calculates unit price from markup adjustments.
 */
export function calculateUnitPriceFromMarkup(
  input: CalculateFromMarkupInput,
): number {
  const quantity = maybeParseNum(input.quantity, 0);

  if (!quantity) {
    throw new Error('Unable to suggest a new price. No quantity provided.');
  }

  // Material cost is broken into two pieces: purchased and fabricated
  // To prevent one inflating the value of the other, we have to calculate
  // the adjustment separately

  const unitMaterialCost = maybeParseNum(input.unitMaterialCost, 0);
  const purchaseMaterialPercent = maybeParseNum(
    input.purchaseMaterialPercent,
    0,
  );
  const purchaseMaterialAmount = maybeParseNum(input.purchaseMaterialAmount, 0);
  const fabricatedMaterialPercent = maybeParseNum(
    input.fabricatedMaterialPercent,
    0,
  );
  const fabricatedMaterialAmount = maybeParseNum(
    input.fabricatedMaterialAmount,
    0,
  );

  const purchaseMaterialAdjustment = calculateAdjustment(
    unitMaterialCost,
    purchaseMaterialPercent,
    purchaseMaterialAmount,
    quantity,
  );
  const fabricatedMaterialAdjustment = calculateAdjustment(
    unitMaterialCost,
    fabricatedMaterialPercent,
    fabricatedMaterialAmount,
    quantity,
  );

  const newUnitMaterialCost =
    unitMaterialCost +
    purchaseMaterialAdjustment +
    fabricatedMaterialAdjustment;

  // The rest of these are just one set of percent and amount
  const unitLaborCost = maybeParseNum(input.unitLaborCost, 0);
  const laborCostPercent = maybeParseNum(input.laborCostPercent, 0);
  const laborCostAmount = maybeParseNum(input.laborCostAmount, 0);

  const newUnitLaborCost =
    unitLaborCost +
    calculateAdjustment(
      unitLaborCost,
      laborCostPercent,
      laborCostAmount,
      quantity,
    );

  const unitServiceCost = maybeParseNum(input.unitServiceCost, 0);
  const serviceCostPercent = maybeParseNum(input.serviceCostPercent, 0);
  const serviceCostAmount = maybeParseNum(input.serviceCostAmount, 0);

  const newUnitServiceCost =
    unitServiceCost +
    calculateAdjustment(
      unitServiceCost,
      serviceCostPercent,
      serviceCostAmount,
      quantity,
    );

  const unitBurdenCost = maybeParseNum(input.unitBurdenCost, 0);
  const burdenCostPercent = maybeParseNum(input.burdenCostPercent, 0);
  const burdenCostAmount = maybeParseNum(input.burdenCostAmount, 0);

  const newUnitBurdenCost =
    unitBurdenCost +
    calculateAdjustment(
      unitBurdenCost,
      burdenCostPercent,
      burdenCostAmount,
      quantity,
    );

  return round(
    newUnitMaterialCost +
      newUnitLaborCost +
      newUnitServiceCost +
      newUnitBurdenCost,
    {
      min: 2,
      small: 3,
    },
  );
}
