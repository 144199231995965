import type { PaginationParams } from '@lib/models';
import { configuratorVendorSchema } from '@lib/validation';
import type { ConfiguratorDynamicFieldDefinition } from '@ui/features/configurator/types';
import { configuratorService } from '@ui/services';

export type ConfiguratorVendorOptionMeta = {
  id: string;
  name: string | null;
};

export const vendorFieldDefinition: ConfiguratorDynamicFieldDefinition<ConfiguratorVendorOptionMeta> =
  {
    endpoints: {
      list: '/configurator/vendors',
    },

    mapOption: (data) => {
      const vendor = configuratorVendorSchema.parse(data);

      return {
        label: vendor.name ?? vendor.id,
        value: vendor.id,
        meta: {
          id: vendor.id,
          name: vendor.name,
        },
      };
    },

    mapOptions: (resources) => {
      return resources.map(vendorFieldDefinition.mapOption);
    },

    fetchOne: () => {
      throw new Error('Not implemented');
    },

    search: ({
      query,
      pagination,
    }: { query: string; pagination: PaginationParams }) => {
      return configuratorService.getFieldOptions({
        fieldDefinition: vendorFieldDefinition,
        query,
        pagination,
      });
    },
  };
