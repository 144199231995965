import type { SelectOptionWithMetadata } from '@lib/responses';
import {
  useConfiguratorStore,
  useDataSource,
} from '@ui/features/configurator/hooks';
import type {
  ConfiguratorFieldType,
  ConfiguratorSingleChoiceValue,
} from '@ui/features/configurator/types';
import {
  type UseComboboxInputValueChange,
  type UseComboboxSelectedItemChange,
  useCombobox,
} from 'downshift';
import { debounce } from 'lodash-es';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Combobox } from '../Combobox';
import { ConfiguratorInputWrapper } from './InputWrapper';
import type { ConfiguratorInputWrapperWidth } from './types';

export type SelectedItem<M = Record<string, unknown>> =
  SelectOptionWithMetadata<string, M>;

const SCROLL_DEBOUNCE_WAIT = 50;
const INPUT_CHANGE_DEBOUNCE_WAIT = 200;

function BaseSingleChoiceComponent<M>({
  field,
  isPending,
  onItemSelected,
  ...widths
}: ConfiguratorInputWrapperWidth & {
  field: ConfiguratorFieldType;
  isPending?: boolean;
  onItemSelected: (item: SelectedItem<M> | null) => void;
}) {
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [inputValue, setInputValue] = useState('');

  const { fields } = useConfiguratorStore();
  const selectedOption = field.value as ConfiguratorSingleChoiceValue<M>;

  const { data, isFetching, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useDataSource<M>(field, fields, queryEnabled, searchQuery);

  const handleSelectedItemChange = useCallback(
    async (changes: UseComboboxSelectedItemChange<SelectedItem<M> | null>) => {
      setInputValue(changes.selectedItem?.label ?? '');
      onItemSelected(changes.selectedItem);
    },
    [onItemSelected],
  );

  const debouncedSetSearchQuery = useMemo(
    () => debounce(setSearchQuery, INPUT_CHANGE_DEBOUNCE_WAIT),
    [],
  );

  const handleInputValueChange = useCallback(
    (changes: UseComboboxInputValueChange<SelectedItem<M>>) => {
      if (changes.type === useCombobox.stateChangeTypes.InputChange) {
        setInputValue(changes.inputValue);
        debouncedSetSearchQuery(changes.inputValue.trim());
      }

      if (
        changes.type === useCombobox.stateChangeTypes.InputBlur ||
        changes.type === useCombobox.stateChangeTypes.FunctionReset
      ) {
        setSearchQuery('');
        setInputValue(changes.selectedItem ? changes.selectedItem.label : '');
      }
    },
    [debouncedSetSearchQuery],
  );

  const handleScrollToBottom = useMemo(
    () =>
      debounce(() => {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }, SCROLL_DEBOUNCE_WAIT),
    [hasNextPage, isFetchingNextPage, fetchNextPage],
  );

  useEffect(() => {
    setInputValue(selectedOption?.label ?? '');
  }, [selectedOption]);

  return (
    <ConfiguratorInputWrapper fieldKey={field.fieldKey} {...widths}>
      <Combobox
        inputId={field.fieldKey}
        isLoading={isFetching || isPending}
        selectedItem={selectedOption}
        inputValue={inputValue}
        placeholder={field.placeholder}
        items={data?.data ?? []}
        isDisabled={field.isDisabled}
        onFocus={() => setQueryEnabled(true)}
        onSelectedItemChange={handleSelectedItemChange}
        onInputValueChange={handleInputValueChange}
        onScrollToBottom={
          field.inputSource?.source_type === 'dynamic'
            ? handleScrollToBottom
            : undefined
        }
      />
    </ConfiguratorInputWrapper>
  );
}

/**
 * Searchable select for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 *
 * Ref: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-656596623
 */
export const ConfiguratorBaseSingleChoiceInput = memo(
  BaseSingleChoiceComponent,
) as typeof BaseSingleChoiceComponent;
