import {
  VisOperationTypeScrapYieldType,
  scrapYieldTypeLabelMapping,
} from '@lib/kysely/visual/tables/operation-type';
import { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import type {
  ConfiguratorFieldState,
  ConfiguratorFieldType,
  ConfiguratorInputSources,
} from '@ui/features/configurator/types';
import type { SelectOptions } from '@ui/util';
import { get } from 'lodash-es';
import density from '../job-steps/data/json/density.json';
import headSlotStyle from '../job-steps/data/json/head-slot-style.json';
import headStyle from '../job-steps/data/json/head-style.json';
import pointStyle from '../job-steps/data/json/point-style.json';
import recessStyle from '../job-steps/data/json/recess-style.json';
import serration from '../job-steps/data/json/serration.json';
import shankLength from '../job-steps/data/json/shank-length.json';
import shankShoulder from '../job-steps/data/json/shank-shoulder.json';
import threadsPerInch from '../job-steps/data/json/thread-per-inch.json';
import threadSize from '../job-steps/data/json/thread-size.json';
import threadType from '../job-steps/data/json/thread-type.json';
import washerSize from '../job-steps/data/json/washer-size.json';
import washerStyle from '../job-steps/data/json/washer-style.json';

const placeholderOptions: SelectOptions = [
  { label: 'Option 1', value: 'option-1' },
  { label: 'Option 2', value: 'option-2' },
];

export const SCRAP_YIELD_NONE_OPTION = {
  label: 'None',
  value: 'N',
};

const scrapYieldOptions = [
  SCRAP_YIELD_NONE_OPTION,
  ...Object.entries(VisOperationTypeScrapYieldType).map(([_key, value]) => ({
    label: scrapYieldTypeLabelMapping[value],
    value,
  })),
];

export const DENSITY_OTHER_OPTION = {
  label: 'Other',
  value: 'OTHER',
};

const densityOptions = [
  DENSITY_OTHER_OPTION,
  ...density.map(({ material, density }) => ({
    label: material,
    value: density,
  })),
];

/**
 * Input sources are (currently) static select options lists. These options
 * are used to populate the choices for Select fields. A field with a static
 * input source specifies a `data_key` that should correspond to one of the
 * entries in this list.
 *
 * Eventually, we will need to:
 *
 *  1. be able to dynamically filter these lists based on other form data,
 *     depending on how the field is defined (e.g. thread* fields depend on
 *     one another)
 *       - provisional solution is to allow an input source to be a callback
 *         that receives the current form values
 *  2. separate out the dynamic input sources with a separate mechanism to
 *     populate them
 *  3. consider abstracting over both static and dynamic sources using
 *     react-query or some other organizing structure so that we can isolate
 *     the logic and build our field components to be agnostic to where the
 *     sources come from
 *  4. consider what changes might need to be made in order to account for
 *     field types with options other than 'single_choice' (e.g. radio groups)
 */
export const inputSources: ConfiguratorInputSources = {
  placeholder: placeholderOptions,

  thread_type: threadType
    .filter((tt) => !tt.inactive)
    .map(({ description, childKey1, plant }) => ({
      label: description,
      value: `${plant}-${childKey1}`,
      meta: {
        childKey1: childKey1,
      },
    })),

  thread_diameter: (
    fields: ConfiguratorFieldState,
    field: ConfiguratorFieldType,
  ) => {
    const currentThreadType = get(fields, [
      `${field.stepId}.thread_type`,
      'value',
      'meta',
      'childKey1',
    ]) as string | undefined;

    return threadSize
      .filter((ts) => {
        if (currentThreadType) {
          return ts.threadType === currentThreadType;
        }

        return true;
      })
      .map(({ plant, threadType, threadSize, uom }) => ({
        label: `${threadSize} ${uom === 'English' ? 'in.' : ''}`.trim(),
        value: `${plant}-${threadSize}-${threadType}-${uom}`,
        meta: {
          threadSize,
        },
      }));
  },

  threads_per_inch: (
    fields: ConfiguratorFieldState,
    field: ConfiguratorFieldType,
  ) => {
    const currentThreadType = get(fields, [
      `${field.stepId}.thread_type`,
      'value',
      'meta',
      'childKey1',
    ]) as string | undefined;

    const currentThreadDiameter = get(fields, [
      `${field.stepId}.thread_diameter`,
      'value',
      'meta',
      'threadSize',
    ]) as string | undefined;

    return threadsPerInch
      .filter((tpi) => {
        if (currentThreadType && tpi.threadType !== currentThreadType) {
          return false;
        }

        if (currentThreadDiameter && tpi.threadSize !== currentThreadDiameter) {
          return false;
        }

        return true;
      })
      .map(({ threadType, threadSize, threadPerInch, plant, uom }) => ({
        label: threadPerInch,
        value: `${plant}-${threadPerInch}-${threadType}-${threadSize}-${uom}`,
        meta: {
          threadType,
          threadSize,
          threadPerInch,
        },
      }));
  },

  // @TODO(shawk): is there a grouping or relation we need to handle?
  head_style: headStyle
    .filter((hs) => !hs.inactive)
    .map(({ description, childKey1 }) => ({
      label: description,
      value: childKey1,
    })),

  head_slot_style: headSlotStyle.map(({ description }) => ({
    label: description,
    value: description,
  })),

  head_recess_style: recessStyle.map(({ description }) => ({
    label: description,
    value: description,
  })),

  point_style: pointStyle.map(({ description }) => ({
    label: description,
    value: description,
  })),

  shank_length: shankLength.map(({ codeDesc }) => ({
    label: codeDesc,
    value: codeDesc,
  })),

  shank_shoulder: shankShoulder
    .filter((ss) => !ss.inactive)
    .map(({ description, childKey1 }) => ({
      label: description,
      value: childKey1,
    })),

  serration_type: serration.map(({ description }) => ({
    label: description,
    value: description,
  })),

  washer_size: washerSize.map(({ partNum, partDescription }) => ({
    label: `${partNum} - ${partDescription}`,
    value: partNum,
  })),

  washer_style: washerStyle.map(({ plant, description }) => ({
    label: description,
    value: description,
  })),

  scrap_yield_type: scrapYieldOptions,

  run_type: Object.entries(VisOpRunRateType).map(([_key, value]) => ({
    label: value,
    value,
  })),

  density: densityOptions,

  // TODO - configurator: Surface from Visual or per site (https://mwcomponents.atlassian.net/browse/MW-783)
  product_code: [
    {
      label: 'SCREW',
      value: 'SCREW',
    },
    {
      label: 'SPACER',
      value: 'SPACER',
    },
    {
      label: 'SEMS',
      value: 'SEMS',
    },
  ],

  category: placeholderOptions,
  part_id_schema: placeholderOptions,
  additional_charges: placeholderOptions,
  one_time_charges: placeholderOptions,
  royalties: placeholderOptions,
  charge_types: placeholderOptions,
};
