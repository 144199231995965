import is from '@sindresorhus/is';
import { mapValues } from 'radash';
import {
  type MarginCalculations,
  type NestedOpCostCalcs,
  calcMargins,
  maybeParseNum,
} from '.';
import { type CostCalculations, calcCosts } from './costs';
import {
  type OpCostCalcsMemo,
  operationCostCalcs,
} from './costs/operation/operation-cost-calcs';
import type { ResolvedOpCostsInput } from './part-history';
import { type PriceFromMarginCalculations, calcPrice } from './pricing/helpers';

interface QuoteLinePricing extends PriceFromMarginCalculations {
  unitPrice: number;
  baseUnitPrice: number | null;
  totalPrice: number;
}

interface QuoteLineMarginCalcs extends MarginCalculations {
  useContributionMargin: boolean;
}

export interface QuoteLineCalculations {
  /** Will be null if missing cost data and/or price*/
  quantity: number;
  pricing: QuoteLinePricing;
  margins: QuoteLineMarginCalcs | null;
  unitCosts: CostCalculations;
  totalCosts: CostCalculations;
  operationCosts: ResolvedOpCostsInput[];
  nestedCostCalcs: NestedOpCostCalcs | null;
  /**! @deprecated use main lvl */
  detailed: {
    margins: QuoteLineMarginCalcs | null;
    operationCosts: ResolvedOpCostsInput[];
    nestedCostCalcs: NestedOpCostCalcs | null;
    unitCosts: CostCalculations;
    totalCosts: CostCalculations;
    pricing: QuoteLinePricing;
    quantity: number;
  };
}

export interface QuoteLineCalcInput {
  unitPrice: number | string | null;
  baseUnitPrice?: number | string | null;
  unitLaborCost?: number | string | null;
  unitBurdenCost?: number | string | null;
  unitMaterialCost?: number | string | null;
  unitServiceCost?: number | string | null;
  automatedUnitPrice?: number | string | null;
  /**! Don't pass this for new/active lines as it won't hold up when quantities
   * change. May have a use as a backup for historic quotes where quantity is
   * constant though */
  calculatedUnitCost?: number | string | null;
  quantity: number | string;
  operationCosts?: ResolvedOpCostsInput[] | null;
  /**! @deprecated pass in on main lvl */
  detailed?: {
    operationCosts?: ResolvedOpCostsInput[] | null;
  };
}

/**
 * Generates calculations for a quote line response
 *
 * @returns margin calculations
 */
export const calcQuoteLine = (
  quoteLine: QuoteLineCalcInput,
): QuoteLineCalculations => {
  const {
    unitLaborCost,
    unitBurdenCost,
    unitMaterialCost,
    unitServiceCost,
    calculatedUnitCost,
    operationCosts,
    quantity,
  } = quoteLine;

  const costInputs = operationCosts ?? quoteLine?.operationCosts ?? [];

  let unitPrice = maybeParseNum(quoteLine.unitPrice) ?? 0; //TODO:(bb) better to handle as nullable

  let opCostCalcsMemo: OpCostCalcsMemo | undefined;
  /** Calcs, sets memo, and returns agg cost calcs from updated qty */
  const getOpCostCalcs = (): OpCostCalcsMemo => {
    if (!is.undefined(opCostCalcsMemo)) {
      return opCostCalcsMemo;
    }

    const ret = operationCostCalcs(costInputs, quantity);

    opCostCalcsMemo = ret;
    return ret;
  };

  let totalCostsMemo: CostCalculations | undefined;

  const qlCalcs = {
    get margins(): QuoteLineMarginCalcs | null {
      const {
        pricing: { unitPrice },
        unitCosts,
      } = qlCalcs;

      if (!unitPrice) {
        return null;
      }

      if (!unitCosts.grossCost) {
        return null;
      }

      const baseMarginCalcs = calcMargins(
        qlCalcs.pricing.unitPrice,
        qlCalcs.unitCosts,
      );

      return {
        ...baseMarginCalcs,
        get useContributionMargin() {
          return Boolean(baseMarginCalcs.contributionMarginPercent);
        },
      };
    },
    get pricing() {
      /** Updates unit price for all getters when any pricing method is called*/
      const wrapWithUpdPriceProxy = (
        obj: PriceFromMarginCalculations,
      ): PriceFromMarginCalculations => {
        return new Proxy(obj, {
          get(target, propKey: keyof PriceFromMarginCalculations) {
            const origMethod = target[propKey];
            if (typeof origMethod === 'function') {
              return (...args: any[]) => {
                const result: ReturnType<typeof origMethod> = Reflect.apply(
                  origMethod,
                  target,
                  args,
                );
                unitPrice = result;
                return result;
              };
            }
            return origMethod;
          },
        });
      };
      const _fromMarginCalcs = calcPrice(qlCalcs.unitCosts);
      const fromMarginCalcs = wrapWithUpdPriceProxy(_fromMarginCalcs);
      const pricing = {
        get unitPrice(): number {
          return unitPrice;
        },
        set unitPrice(price: number) {
          unitPrice = price;
        },
        get baseUnitPrice(): number | null {
          return maybeParseNum(quoteLine.baseUnitPrice) ?? null;
        },
        get totalPrice(): number {
          return pricing.unitPrice * qlCalcs.quantity;
        },
        ...fromMarginCalcs,
      };
      return pricing;
    },
    get quantity(): number {
      return +quoteLine.quantity || 0;
    },
    /** Gross cost defaults to the calculated (estimated total) cost if it's all we
     * have  */
    get unitCosts(): CostCalculations {
      const qty = qlCalcs.quantity;
      const unitCostCalcs = mapValues(qlCalcs.totalCosts, (cost) =>
        qty ? cost / qty : 0,
      );
      return unitCostCalcs;
    },
    get totalCosts(): CostCalculations {
      if (totalCostsMemo) {
        return totalCostsMemo;
      }

      const totalCostCalcsFromOps = getOpCostCalcs().agg;

      let ret: CostCalculations;

      if (!totalCostCalcsFromOps || !totalCostCalcsFromOps.grossCost) {
        // TODO: rethink
        const qty = qlCalcs.quantity;
        // Non-operation based aggs persisted in db (could be vis quote), only use if we can't calculate
        // detailed costs from operations (workorder/master)
        const fallbackUnitCosts = calcCosts({
          laborCost: unitLaborCost,
          burdenCost: unitBurdenCost,
          materialCost: unitMaterialCost,
          serviceCost: unitServiceCost,
        });
        // if we don't have individual costs but we do have a
        // `calculatedUnitCost` (from imports), use that for gross cost  as a
        // last resort
        const calcUnitCostNum = maybeParseNum(calculatedUnitCost);
        if (!fallbackUnitCosts.grossCost && calcUnitCostNum) {
          ret = mapValues(
            {
              ...fallbackUnitCosts,
              grossCost: calcUnitCostNum,
            },
            (cost) => cost * qty,
          );
        } else {
          ret = mapValues(fallbackUnitCosts, (cost) => cost * qty);
        }
      } else {
        ret = totalCostCalcsFromOps;
      }

      totalCostsMemo = ret;
      return ret;
    },
    get operationCosts(): ResolvedOpCostsInput[] {
      return getOpCostCalcs().resolvedOpCosts;
    },
    get nestedCostCalcs(): NestedOpCostCalcs | null {
      return getOpCostCalcs().nested;
    },
    // TODO: temp as it's own, should be the default costing method
    /** @deprecated Access from the root object going forward*/
    get detailed() {
      const detailed = {
        get margins() {
          return qlCalcs.margins;
        },
        get pricing() {
          return qlCalcs.pricing;
        },
        get quantity() {
          return qlCalcs.quantity;
        },
        get operationCosts() {
          return qlCalcs.operationCosts;
        },
        get nestedCostCalcs() {
          return qlCalcs.nestedCostCalcs;
        },
        get unitCosts() {
          return qlCalcs.unitCosts;
        },
        get totalCosts() {
          return qlCalcs.totalCosts;
        },
      };

      return detailed;
    },
  };

  return qlCalcs;
};
