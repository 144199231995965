import { Checkbox, IconButton, Tooltip } from '@chakra-ui/react';
import { CircleInfoIcon } from '@ui/components/Icons';
import { useConfiguratorStore } from '@ui/features/configurator/hooks/use-configurator-store';
import { updateField } from '@ui/features/configurator/store';
import type { ConfiguratorBooleanType } from '@ui/features/configurator/types';
import { memo } from 'react';
import { ConfiguratorInputWrapper } from './InputWrapper';
import type { ConfiguratorInputProps } from './types';

const BooleanInputComponent = ({
  fieldKey,
  ...widths
}: ConfiguratorInputProps) => {
  const { fields, dispatch } = useConfiguratorStore();

  const field = fields[fieldKey];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateField(fieldKey, e.target.checked));
  };

  return (
    <ConfiguratorInputWrapper fieldKey={fieldKey} {...widths} hideLabel>
      <Checkbox
        id={fieldKey}
        isInvalid={field.isInvalid}
        isChecked={(field.value as ConfiguratorBooleanType) ?? false}
        onChange={handleChange}
      >
        {field.name}

        {field.description && (
          <Tooltip label={field.description} placement="end">
            <IconButton
              size="xs"
              variant="none"
              verticalAlign="inherit"
              aria-label="Description"
              icon={<CircleInfoIcon color="mw.lightGrey" fontSize="md" />}
            />
          </Tooltip>
        )}
      </Checkbox>
    </ConfiguratorInputWrapper>
  );
};

/**
 * Checkbox input for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorBooleanInput = memo(BooleanInputComponent);
