import type {
  ConfigurationFieldSchema,
  ConfigurationStepSchema,
} from '@ui/features/configurator/types';

const FIELD_IDS = {
  volume_use_metric: 'volume_use_metric',
  volume_density: 'volume_density',
  volume_custom_density: 'volume_custom_density',
  volume_head_diameter: 'volume_head_diameter',
  volume_head_height: 'volume_head_height',
  volume_shank_diameter: 'volume_shank_diameter',
  volume_shank_length: 'volume_shank_length',
  volume_shank_two_diameter: 'volume_shank_two_diameter',
  volume_shank_two_length: 'volume_shank_two_length',
  volume_shank_three_diameter: 'volume_shank_three_diameter',
  volume_shank_three_length: 'volume_shank_three_length',
} as const;

export const VOLUME_CALCULATIONS_FIELD_IDS = FIELD_IDS;

export const VOLUME_CALCULATIONS_STEP_KEYS = {
  volume: 'volume',
};

const volumeDensityField = {
  name: 'Material',
  field_id: FIELD_IDS.volume_density,
  input_type: 'single_choice',
  input_source: {
    source_type: 'static',
    data_key: 'density',
  },
  validations: [],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const volumeCustomDensityField = {
  name: 'Custom Density (lb/in³)',
  field_id: FIELD_IDS.volume_custom_density,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.volume_density,
      dependency_type: 'selected_value',
      selected_value_options: ['OTHER'],
    },
  ],
  dependency_mode: null,
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const volumeHeadDiameterField = {
  name: 'Head Diameter',
  field_id: FIELD_IDS.volume_head_diameter,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const volumeHeadHeightField = {
  name: 'Head Height',
  field_id: FIELD_IDS.volume_head_height,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const volumeShankDiameterField = {
  name: 'Shank Diameter',
  field_id: FIELD_IDS.volume_shank_diameter,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const volumeShankLengthField = {
  name: 'Shank Length',
  field_id: FIELD_IDS.volume_shank_length,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const volumeShankTwoDiameterField = {
  name: 'Shank Diameter 2',
  field_id: FIELD_IDS.volume_shank_two_diameter,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const volumeShankTwoLengthField = {
  name: 'Shank Length 2',
  field_id: FIELD_IDS.volume_shank_two_length,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [],
  dependency_mode: null,
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const volumeShankThreeDiameterField = {
  name: 'Shank Diameter 3',
  field_id: FIELD_IDS.volume_shank_three_diameter,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const volumeShankThreeLengthField = {
  name: 'Shank Length 3',
  field_id: FIELD_IDS.volume_shank_three_length,
  input_type: 'number',
  validations: [
    {
      validate: 'function',
      name: 'numericality',
      greater_than: 0,
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const volumeUseMetricField = {
  name: 'Input Metric (mm)',
  field_id: FIELD_IDS.volume_use_metric,
  input_type: 'boolean',
  validations: [],
  dependencies: [],
  dependency_mode: null,
  description: 'Supply dimensions in Metric (mm) instead of inches',
} satisfies ConfigurationFieldSchema;

export const defaultVolumeStep = {
  id: '9ABC4996-BE82-43CB-A0D0-4E8C369028D7',
  step_key: VOLUME_CALCULATIONS_STEP_KEYS.volume,
  name: 'Volume',
  step_type: 'volume_calculations',
  fields: [
    volumeUseMetricField,
    volumeHeadDiameterField,
    volumeHeadHeightField,
    volumeShankDiameterField,
    volumeShankLengthField,
    volumeShankTwoDiameterField,
    volumeShankTwoLengthField,
    volumeShankThreeDiameterField,
    volumeShankThreeLengthField,
    volumeDensityField,
    volumeCustomDensityField,
  ],
} satisfies ConfigurationStepSchema;
