import type { NegotiatedPriceResponse } from '@lib/responses/negotiated-price';
import { isAxiosError } from 'axios';
import type { API } from './api';

export class NegotiatedPriceService {
  constructor(private api: API) {}

  getNegotiatedPrice = async (query: {
    customerId: string | null;
    partId: string;
  }): Promise<NegotiatedPriceResponse | null> => {
    if (!query.customerId) {
      return null;
    }

    try {
      return await this.api.get<NegotiatedPriceResponse>(
        '/negotiated-price',
        query,
      );
    } catch (error) {
      // return null if it's a client (assumed not found error)
      if (isAxiosError(error) && error?.response?.status === 400) {
        console.log(
          `No negotiated price found for ${query.customerId} ${query.partId}`,
        );
        return null;
      }
      // otherwise bubble for now
      throw error;
    }
  };
}
