import { PaginationMetadataModel, type WithPagination } from '@lib/models';
import type { SelectOptionWithMetadata } from '@lib/responses';
import { isError } from '@sindresorhus/is';
import {
  type UseInfiniteQueryResult,
  keepPreviousData,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { DataSourceError } from '@ui/features/configurator/data';
import type {
  ConfiguratorFieldState,
  ConfiguratorFieldType,
} from '@ui/features/configurator/types';
import { useAuth } from '@ui/state/auth';
import { last } from 'lodash-es';
import {
  fieldDefinitionMap,
  getFieldDefinition,
} from '../data/field-definitions';
import { getStaticOptions } from '../store/helpers/get-static-options';

/**
 * Sources options for a field based on the step's `input_source` definition.
 *
 * @NOTE(shawk): It's important that we pass `values` in from the component so
 * that zustand & react-tracked can properly subscribe to the right keys. If we
 * access `values` through the `useConfiguratorStore` hook within `useDataSource`
 * then all `single_choice` fields will re-render when a field value changes.
 */
export function useDataSource<M>(
  field: ConfiguratorFieldType,
  fields: ConfiguratorFieldState,
  enabled: boolean,
  q: string,
) {
  const { currentSite } = useAuth();

  if (!field.inputSource) {
    throw new Error('No input source provided');
  }

  // @TODO(shawk): remove when we have a `useRequiredAuth` hook or better type guards in place
  if (!currentSite) {
    throw new Error('Users must have a current site to use this feature');
  }

  /**
   * Static data sources can be populated without any async/fetch calls
   */
  if (field.inputSource.source_type === 'static') {
    const options = getStaticOptions(field, fields).filter((option) => {
      if (q) {
        const searchTerm = q.toLowerCase();

        return (
          option.label.toLowerCase().includes(searchTerm) ||
          option.value.toLowerCase().includes(searchTerm)
        );
      }

      return true;
    });

    return {
      data: {
        data: options,
        pagination: {
          page: 1,
          perPage: 100,
          totalCount: options.length,
          totalPages: 1,
        },
      },
      isFetching: false,
      error: null,
      hasNextPage: false,
      fetchNextPage: () => void 0,
      /**
       * @NOTE(shawk): We lie and say this is a `UseInfiniteQueryResult` so we don't
       * have to mock out all the (many) properties of the actual data type.
       * The caller is expected to only rely on `data`, `isFetching`, and `error`
       * when the `source_type` is "static".
       */
    } as unknown as UseInfiniteQueryResult<
      WithPagination<SelectOptionWithMetadata<string, M>>,
      never
    >;
  }

  /**
   * Dynamic data sources are async and may be paginated
   */
  if (field.inputSource.source_type === 'dynamic') {
    const queryKey = [
      currentSite.code,
      'configurator',
      'dataSource',
      field.inputSource.source_key,
      q,
    ];

    if (!(field.inputSource.source_key in fieldDefinitionMap)) {
      throw new Error(
        'Could not find field definition for dynamic input source',
      );
    }

    const fieldDefinition = getFieldDefinition(field.inputSource.source_key);

    return useInfiniteQuery<
      WithPagination<SelectOptionWithMetadata<string, M>>,
      Error,
      WithPagination<SelectOptionWithMetadata<string, M>>,
      typeof queryKey,
      number
    >({
      queryKey,
      queryFn: ({ pageParam }) => {
        return fieldDefinition
          .search({
            query: q,
            pagination: {
              page: pageParam,
              perPage: 100,
            },
          })
          .catch((e) => {
            throw new DataSourceError(
              isError(e) ? e.message : 'Unexpected data source failure',
              queryKey,
            );
          }) as Promise<WithPagination<SelectOptionWithMetadata<string, M>>>;
      },
      enabled,
      throwOnError: true,
      initialPageParam: 1,
      placeholderData: keepPreviousData,
      getNextPageParam: (lastPage) => {
        const pagination = new PaginationMetadataModel(lastPage.pagination);
        return pagination.hasNextPage() ? pagination.nextPage() : null;
      },
      select: (data) => {
        return {
          data: data.pages.flatMap((page) => page.data),
          pagination:
            last(data.pages)?.pagination ??
            PaginationMetadataModel.defaultMetadata,
        };
      },
    });
  }

  throw new Error(`Unknown input source: ${field.inputSource}`);
}
