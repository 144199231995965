import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { memo } from 'react';
import { getUntrackedObject } from 'react-tracked';
import { match } from 'ts-pattern';
import { ConfiguratorBooleanInput } from '../BooleanInput';
import { ConfiguratorCalculatedNumberInput } from '../CalculatedNumberInput';
import { ConfiguratorDescriptionInput } from '../DescriptionInput';
import { ConfiguratorMaterialInput } from '../MaterialInput';
import { ConfiguratorNumberInput } from '../NumberInput';
import { ConfiguratorOperationTypeInput } from '../OperationTypeInput';
import { ConfiguratorSingleChoiceInput } from '../SingleChoiceInput';
import { ConfiguratorTextInput } from '../TextInput';
import type { ConfiguratorInputProps } from '../types';
import { ConfiguratorFieldErrorBoundary } from './ErrorBoundary';

function ConfiguratorFieldComponent({
  fieldKey,
  ...props
}: ConfiguratorInputProps) {
  const { fields } = useConfiguratorStore();
  const field = fields[fieldKey];

  return (
    <ConfiguratorFieldErrorBoundary>
      {match(field.inputType)
        .with('text', () => (
          <ConfiguratorTextInput fieldKey={fieldKey} {...props} />
        ))
        .with('number', () => (
          <ConfiguratorNumberInput fieldKey={fieldKey} {...props} />
        ))
        .with('calculated_number', () => (
          <ConfiguratorCalculatedNumberInput fieldKey={fieldKey} {...props} />
        ))
        .with('single_choice', () => (
          <ConfiguratorSingleChoiceInput fieldKey={fieldKey} {...props} />
        ))
        .with('boolean', () => (
          <ConfiguratorBooleanInput fieldKey={fieldKey} {...props} />
        ))
        .with('operation', () => (
          <ConfiguratorOperationTypeInput fieldKey={fieldKey} {...props} />
        ))
        .with('material', () => (
          <ConfiguratorMaterialInput fieldKey={fieldKey} {...props} />
        ))
        .with('description', () => (
          <ConfiguratorDescriptionInput fieldKey={fieldKey} {...props} />
        ))
        .otherwise(() => {
          if (import.meta.env.DEV) {
            console.warn(
              `Unknown input type "${field.inputType}"`,
              getUntrackedObject(field),
            );
          }

          return null;
        })}
    </ConfiguratorFieldErrorBoundary>
  );
}

export const ConfiguratorField = memo(ConfiguratorFieldComponent);
