import { Checkbox, Textarea } from '@chakra-ui/react';
import { updateField } from '@ui/features/configurator/store';
import { memo } from 'react';
import { useConfiguratorStore } from '../../hooks/use-configurator-store';
import type { ConfiguratorDescriptionFieldType } from '../../types';
import { ConfiguratorInputWrapper } from './InputWrapper';
import { inputStyles } from './styles';
import type { ConfiguratorInputProps } from './types';

function DescriptionInputComponent({
  fieldKey,
  ...widths
}: ConfiguratorInputProps) {
  const { fields, dispatch } = useConfiguratorStore();

  const field = fields[fieldKey] as ConfiguratorDescriptionFieldType;

  const { value, override } = field.value ?? { value: '', override: false };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateField(fieldKey, { value: e.target.value, override }));
  };

  const toggleOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateField(fieldKey, { value, override: e.target.checked }));
  };

  return (
    <ConfiguratorInputWrapper fieldKey={fieldKey} {...widths} maxWidth="420px">
      <Textarea
        placeholder={field.placeholder}
        onChange={handleChange}
        value={value}
        disabled={!override}
        {...inputStyles}
        minHeight="7rem"
        _disabled={{
          bg: 'mw.panelGrey',
          color: '#666',
        }}
      />

      <Checkbox
        isChecked={override}
        onChange={toggleOverride}
        isRequired={false}
      >
        Override
      </Checkbox>
    </ConfiguratorInputWrapper>
  );
}

/**
 * Description input for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorDescriptionInput = memo(DescriptionInputComponent);
