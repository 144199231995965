import type { PaginationParams } from '@lib/models';
import { configuratorResourceSchema } from '@lib/validation';
import type {
  ConfiguratorDynamicFieldDefinition,
  ConfiguratorFieldType,
  ConfiguratorSingleChoiceValue,
} from '@ui/features/configurator/types';
import { configuratorService } from '@ui/services';

export type ConfiguratorResourceOptionMeta = {
  resourceId: string;
  resourceType: string;
  setupCostPerHr: number;
  runCostPerHr: number;
  runCostPerUnit: number;
  burdenPerHrRun: number;
  burdenPerHrSetup: number;
  burdenPerOperation: number;
  burdenPerUnitRun: number;
  burdenPercentRun: number;
  burdenPercentSetup: number;
};

export const resourceFieldDefinition: ConfiguratorDynamicFieldDefinition<ConfiguratorResourceOptionMeta> =
  {
    endpoints: {
      list: '/configurator/resources',
      get: '/configurator/resource',
    },

    mapOption: (data) => {
      const resource = configuratorResourceSchema.parse(data);

      return {
        label: resource.resource_id,
        value: resource.resource_id,
        meta: {
          resourceId: resource.resource_id,
          resourceType: resource.resource_type,
          setupCostPerHr: resource.setup_cost_per_hr,
          runCostPerHr: resource.run_cost_per_hr,
          runCostPerUnit: resource.run_cost_per_unit,
          burdenPerHrRun: resource.bur_per_hr_run,
          burdenPerHrSetup: resource.bur_per_hr_setup,
          burdenPerOperation: resource.bur_per_operation,
          burdenPerUnitRun: resource.bur_per_unit_run,
          burdenPercentRun: resource.bur_percent_run,
          burdenPercentSetup: resource.bur_percent_setup,
        },
      };
    },

    mapOptions: (resources) => {
      return resources.map(resourceFieldDefinition.mapOption);
    },

    fetchOne: (id: string) => {
      return configuratorService.getSingleOption({
        fieldDefinition: resourceFieldDefinition,
        id,
      });
    },

    search: ({
      query,
      pagination,
    }: { query: string; pagination: PaginationParams }) => {
      return configuratorService.getFieldOptions({
        fieldDefinition: resourceFieldDefinition,
        query,
        pagination,
      });
    },
  };

export function getResourceFieldValue(field: ConfiguratorFieldType) {
  if (field.inputType !== 'single_choice') {
    throw new Error(`Invalid resource field with key ${field.fieldKey}`);
  }

  return field.value as ConfiguratorSingleChoiceValue<ConfiguratorResourceOptionMeta>;
}
