import { Box, Flex } from '@chakra-ui/react';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { useGlobalState } from '@ui/state/global';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Modal from '../Modal';
import { NavigationBar } from '../NavigationBar';

const PageLayout = () => {
  const { activeModal } = useGlobalState();
  return (
    <Box>
      <Modal isOpen={!!activeModal} {...activeModal} />
      <Flex direction={'row'} transition={'padding-left 200ms'}>
        <NavigationBar />
        {/* @NOTE(shawk): ensure <NavigationBar /> stays visible
            while descendent lazy-loaded routes are loading */}
        <Suspense fallback={<FullScreenLoader />}>
          <Outlet />
        </Suspense>
      </Flex>
    </Box>
  );
};

export default PageLayout;
