import { paginationSchema } from '@lib/models/pagination';
import { z } from 'zod';
import type { SerializedConfigurationState } from '../models/configurator';

export const ConfiguratorFieldOptionsQuery = z
  .object({
    q: z.string().optional(),
    productCode: z.string().optional(),
    meta: z.record(z.any()).optional(),
  })
  .merge(paginationSchema);

export type ConfiguratorFieldOptionsQuery = z.infer<
  typeof ConfiguratorFieldOptionsQuery
>;

export type ConfiguratorCreatePartRequest = SerializedConfigurationState & {
  quoteId: string;
  lineNumber: number;
};
