import type { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from './auth/navigationClient';
import { FullScreenLoader } from './components/FullScreenLoader';
import { WithPermission } from './components/Permission';
import ProtectedRoute from './components/ProtectedRoute';
import PageLayout from './components/layout/PageLayout';
import { RoutesConfig } from './config/routes';
import { AuthProvider } from './state/auth';
import { GlobalStateProvider } from './state/global';

const QuoteQueue = React.lazy(() => import('./pages/QuoteQueue'));
const UnderConstruction = React.lazy(
  () => import('./pages/UnderConstruction/UnderConstruction'),
);
const Admin = React.lazy(() => import('./pages/Admin/Admin'));
const ConfigsPanel = React.lazy(
  () => import('./pages/Admin/Config/ConfigsPanel'),
);
const AdminMetrics = React.lazy(
  () => import('./pages/Admin/Metrics/AdminMetrics'),
);
const AdminSiteIndex = React.lazy(
  () => import('./pages/Admin/Site/AdminSiteIndex'),
);
const AdminUserIndex = React.lazy(
  () => import('./pages/Admin/User/AdminUserIndex'),
);
const LoggedOut = React.lazy(() => import('./pages/LoggedOut'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Profile = React.lazy(() => import('./pages/Profile'));
const QuoteFeedback = React.lazy(
  () => import('./pages/QuoteFeedback/QuoteFeedback'),
);
const QuoteNew = React.lazy(() => import('./pages/QuoteNew'));
const QuoteShow = React.lazy(() => import('./pages/QuoteShow'));
const QuoteView = React.lazy(() => import('./pages/QuoteView/QuoteView'));

type AppProps = {
  msalInstance: IPublicClientApplication;
};

export function App({ msalInstance }: AppProps) {
  // configure MSAL redirects between pages to use internal routing
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  const {
    quoteQueue,
    quoteNew,
    quoteView,
    quoteShow,
    quoteFeedback,
    profile,
    admin,
    customers,
    metrics,
    dashboard,
    adminConfig: adminQuoting,
    adminUsers,
    adminSites,
    adminMetrics,
  } = RoutesConfig;

  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <GlobalStateProvider>
          <Suspense fallback={<FullScreenLoader />}>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <PageLayout />
                  </ProtectedRoute>
                }
              >
                <Route
                  path={dashboard.path}
                  element={<UnderConstruction {...dashboard} />}
                />
                <Route index element={<Navigate to={quoteQueue.path} />} />
                <Route
                  path={quoteQueue.path}
                  element={
                    <WithPermission permissions={quoteQueue.permissions}>
                      <QuoteQueue />
                    </WithPermission>
                  }
                />
                <Route path={quoteNew.path} element={<QuoteNew />} />
                <Route path={`${quoteShow.path}`} element={<QuoteShow />} />
                <Route path={`${quoteView.path}`} element={<QuoteView />} />
                <Route
                  path={`${quoteFeedback.path}`}
                  element={<QuoteFeedback />}
                />
                <Route
                  path={metrics.path}
                  element={
                    <WithPermission permissions={metrics.permissions}>
                      <UnderConstruction {...metrics} />
                    </WithPermission>
                  }
                />

                <Route
                  path={customers.path}
                  element={
                    <WithPermission permissions={customers.permissions} page>
                      <UnderConstruction {...customers} />
                    </WithPermission>
                  }
                />
                <Route
                  path={admin.path}
                  element={
                    <WithPermission permissions={admin.permissions} page>
                      <Admin />
                    </WithPermission>
                  }
                >
                  <Route
                    path={adminQuoting.path}
                    element={
                      <WithPermission
                        permissions={adminQuoting.permissions}
                        page
                      >
                        <ConfigsPanel />
                      </WithPermission>
                    }
                  />
                  <Route
                    path={adminUsers.path}
                    element={
                      <WithPermission permissions={adminUsers.permissions} page>
                        <AdminUserIndex />
                      </WithPermission>
                    }
                  />
                  <Route
                    path={adminSites.path}
                    element={
                      <WithPermission permissions={adminSites.permissions} page>
                        <AdminSiteIndex />
                      </WithPermission>
                    }
                  />
                  <Route
                    path={adminMetrics.path}
                    element={
                      <WithPermission
                        permissions={adminMetrics.permissions}
                        page
                      >
                        <AdminMetrics />
                      </WithPermission>
                    }
                  />
                </Route>
                <Route path={profile.path} element={<Profile />} />
              </Route>
              <Route path="/auth/redirect" element={null} />
              <Route path="/logout/redirect" element={<LoggedOut />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </GlobalStateProvider>
      </AuthProvider>
    </MsalProvider>
  );
}
