import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { useConfiguratorStore } from '@ui/features/configurator/hooks/use-configurator-store';
import { updateField } from '@ui/features/configurator/store';
import type { ConfiguratorTextType } from '@ui/features/configurator/types';
import { memo } from 'react';
import { ConfiguratorInputIcon } from './InputIcon';
import { ConfiguratorInputWrapper } from './InputWrapper';
import { inputStyles } from './styles';
import type { ConfiguratorInputProps } from './types';

const TextInputComponent = ({
  fieldKey,
  ...widths
}: ConfiguratorInputProps) => {
  const { fields, dispatch } = useConfiguratorStore();

  const field = fields[fieldKey];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateField(fieldKey, e.target.value));
  };

  return (
    <ConfiguratorInputWrapper fieldKey={fieldKey} {...widths}>
      <InputGroup>
        {field.leftIcon && (
          <InputLeftElement pointerEvents="none">
            <ConfiguratorInputIcon icon={field.leftIcon} />
          </InputLeftElement>
        )}

        <Input
          id={fieldKey}
          type="text"
          onChange={handleChange}
          value={(field.value as ConfiguratorTextType) ?? ''}
          placeholder={field.placeholder}
          {...inputStyles}
        />

        {field.rightIcon && (
          <InputRightElement pointerEvents="none">
            <ConfiguratorInputIcon icon={field.rightIcon} />
          </InputRightElement>
        )}
      </InputGroup>
    </ConfiguratorInputWrapper>
  );
};

/**
 * Text input for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorTextInput = memo(TextInputComponent);
