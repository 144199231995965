import type { PaginationParams } from '@lib/models';
import { configuratorMaterialSchema } from '@lib/validation';
import type {
  ConfiguratorDynamicFieldDefinition,
  ConfiguratorFieldState,
  ConfiguratorMaterialFieldType,
} from '@ui/features/configurator/types';
import { configuratorService } from '@ui/services';

export type ConfiguratorMaterialOptionMeta = {
  partId: string;
  description?: string | null;
  stockUm: string; // @TODO(shawk): enum?
  unitMaterialCost: number;
};

export const materialFieldDefinition: ConfiguratorDynamicFieldDefinition<ConfiguratorMaterialOptionMeta> =
  {
    endpoints: {
      list: '/configurator/materials',
    },

    mapOption: (data) => {
      const material = configuratorMaterialSchema.parse(data);

      return {
        label: material.id,
        value: material.id,
        description: material?.description,
        meta: {
          partId: material.id,
          description: material.description,
          stockUm: material.stock_um,
          unitMaterialCost: material.unit_material_cost,
        },
      };
    },

    mapOptions: (materials) => {
      return materials.map(materialFieldDefinition.mapOption);
    },

    fetchOne: () => {
      throw new Error('Not implemented');
    },

    search: ({
      query,
      pagination,
    }: { query: string; pagination: PaginationParams }) => {
      return configuratorService.getFieldOptions({
        fieldDefinition: materialFieldDefinition,
        query,
        pagination,
        productCode: 'ZRAW',
      });
    },
  };

export function getMaterialField(
  fields: ConfiguratorFieldState,
  fieldKey: string,
): ConfiguratorMaterialFieldType {
  const field = fields[fieldKey];

  if (field.inputType !== 'material') {
    throw new Error(`Invalid operation type field with key ${fieldKey}`);
  }

  return field as ConfiguratorMaterialFieldType;
}

export const washerMaterialFieldDefinition = {
  ...materialFieldDefinition,
  search: ({
    query,
    pagination,
  }: { query: string; pagination: PaginationParams }) => {
    return configuratorService.getFieldOptions({
      fieldDefinition: materialFieldDefinition,
      query,
      pagination,
      productCode: 'WASHER',
    });
  },
};
