import { Box, Text } from '@chakra-ui/react';
import { Show } from '@ui/components/Show';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { updateField } from '@ui/features/configurator/store';
import { memo, useCallback } from 'react';
import { getUntrackedObject } from 'react-tracked';
import {
  ConfiguratorBaseSingleChoiceInput,
  type SelectedItem,
} from './BaseSingleChoiceInput';
import type { ConfiguratorInputProps } from './types';

function SingleChoiceComponent({
  fieldKey,
  ...widths
}: ConfiguratorInputProps) {
  const { fields: trackedFields, dispatch } = useConfiguratorStore();

  /**
   * Using the tracked fields causes an issue with reusable operations:
   * proxy must report the same value for the non-writable, non-configurable property
   *
   * This is probably a sign to dump react-tracked, but I will do that in another PR
   * TODO: configurator - MW-701 - Remove react tracked
   */
  const fields = getUntrackedObject(trackedFields);

  if (!fields) {
    throw new Error('Tracked object not found');
  }

  const field = fields[fieldKey];
  // @ts-expect-error `meta` missing from single choice input type
  const description = field.value?.meta?.description;

  if (field.inputType !== 'single_choice') {
    throw new Error('Invalid input type');
  }

  if (!field.inputSource) {
    throw new Error(
      `single_choice inputs must have an associated input_source, but ${fieldKey} had none`,
    );
  }

  const handleSelectedItemChange = useCallback(
    async (item: SelectedItem | null) => {
      dispatch(updateField(fieldKey, item));
    },
    [dispatch, fieldKey],
  );

  return (
    <Box
      maxWidth={widths.maxWidth ?? '200px'}
      minWidth={widths.minWidth ?? '200px'}
    >
      <ConfiguratorBaseSingleChoiceInput
        field={field}
        onItemSelected={handleSelectedItemChange}
        {...widths}
      />

      <Show when={description}>
        <Text mt={2} fontSize="small">
          {description}
        </Text>
      </Show>
    </Box>
  );
}

/**
 * Searchable select for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorSingleChoiceInput = memo(SingleChoiceComponent);
