import { useMemo } from 'react';
import defaultStrings from './i18n.js';

type DefaultStrings = typeof defaultStrings;
type Sections = keyof DefaultStrings;

type ValidLocales<S extends Sections> = keyof DefaultStrings[S];
type ValidKeys<
  S extends Sections,
  L extends ValidLocales<S>,
> = keyof DefaultStrings[S][L];

const getLocalization = <S extends Sections, L extends ValidLocales<S>>(
  section: S,
  forceLocale?: L,
) => {
  let locale: ValidLocales<S> = forceLocale ?? 'en';
  let strings: DefaultStrings[S][typeof locale];

  // Automatically detect locale if not forced
  if (!forceLocale) {
    const userLang = navigator.language as L;
    if (defaultStrings[section][userLang]) {
      locale = userLang;
    }
  }

  // Load strings for selected locale
  strings = defaultStrings[section][locale];

  const getString = <K extends ValidKeys<S, ValidLocales<S>>>(
    key: K,
  ): DefaultStrings[S][ValidLocales<S>][K] => {
    return strings[key];
  };

  return getString;
};

export type LocalizedStrings<S extends Sections> = {
  [K in S]: DefaultStrings[K][ValidLocales<K>];
};

const getLocalizationBatch = <S extends Sections>(
  sections: S[],
): LocalizedStrings<S> => {
  const localizedStrings: Partial<LocalizedStrings<S>> = {};

  for (const section of sections) {
    let locale: ValidLocales<S> = 'en'; // Default to en
    let strings: DefaultStrings[S][typeof locale];

    // Automatically detect locale if not forced
    const userLang = navigator.language;
    if (isLocale(userLang, section)) {
      locale = userLang;
    }
    strings = defaultStrings[section][locale];

    localizedStrings[section] = strings;
  }

  return localizedStrings as LocalizedStrings<S>;
};

function isLocale<S extends Sections>(
  key: string | number | symbol,
  section: S,
): key is ValidLocales<S> {
  return typeof key === 'string' && key in defaultStrings[section];
}

export const useLocalizationBatch = <S extends Sections>(
  sections: S[],
): LocalizedStrings<S> => {
  return useMemo(() => getLocalizationBatch(sections), sections);
};

export const useLocalization = <S extends Sections, L extends ValidLocales<S>>(
  section: S,
  forceLocale?: L,
) => {
  return useMemo(
    () => getLocalization(section, forceLocale),
    [section, forceLocale],
  );
};
