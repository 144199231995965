// Localization configuration
export interface Ii18nSection {
  [section: string]: {
    en: {
      [key: string]: string;
    };
    [locale: string]: {
      [key: string]: string;
    };
  };
}

const i18n = {
  dateFormat: {
    en: {
      short: 'yyyy-dd-MM',
      long: 'MMMM Do YYYY',
      longWithTime: 'MMMM Do YYYY, h:mm:ss a',
    },
    es: {
      short: 'DD/MM/YYYY',
      long: 'Do MMMM YYYY',
      longWithTime: 'Do MMMM YYYY, h:mm:ss a',
    },
  },
  Header: {
    en: {
      logoAltText: 'MW Components Logo',
      appTitle: 'QuickQuote',
    },
    es: {
      logoAltText: 'Logo de MW Components',
      appTitle: 'QuickQuote',
    },
    // ... more languages
  },

  NavigationBar: {
    en: {
      logoAltText: 'MW Components Logo',
      expandMenu: 'Expand Menu',
      collapseMenu: 'Collapse Menu',
      appTitle: 'QuickQuote',
    },
    es: {
      logoAltText: 'Logo de MW Components',
      expandMenu: 'Expandir Menú',
      collapseMenu: 'Colapsar Menú',
      appTitle: 'QuickQuote',
    },
  },
  Modal: {
    en: {
      close: 'Close',
      cancel: 'Cancel',
      customerSearchSelection: 'Select customer',
      customerSwapSelection: 'Change customer',
      partSearchSelection: 'Add Parts to Quote',
      partSearchFollowUpSelection: 'Add another part to Quote',
      resetQuoteWarning: 'Reset Quote',
      resetQuoteConfirmation:
        'Are you sure you want to reset the quote? All changes will be lost.',
      confirmCustomerChange: 'Confirm change of customer?',
      confirmCustomerChangeMessage: `You will lose all changes made to the current quote.\nAnd create a new quote for the selected customer.`,
      confirmCustomerChangeCTA: 'Confirm Change Customer',
    },
    es: {
      close: 'Cerrar',
      cancel: 'Cancelar',
      customerSearchSelection: 'Seleccionar cliente',
      customerSwapSelection: 'Cambiar cliente',
      partSearchSelection: 'Seleccionar parte',
      partSearchFollowUpSelection: 'Agregar otra parte a la cotización',
      resetQuoteWarning: 'Reiniciar Cotización',
      resetQuoteConfirmation:
        '¿Está seguro de que desea reiniciar la cotización? Se perderán todos los cambios.',
      confirmCustomerChange: 'Confirmar Cambio de Cliente',
      confirmCustomerChangeMessage: `¿Está seguro de que desea cambiar el cliente? \n \n Esto comenzará a crear una nueva cotización para el cliente seleccionado.  Perderá todos los cambios realizados a la cotización actual.`,
      confirmCustomerChangeCTA: 'Confirmar Cambio de Cliente',
    },
  },
  QuoteQueue: {
    en: {
      title: 'Quote Queue',
      subtitle: 'View and manage quotes',
      search: 'Search',
      searchPlaceholder: 'Search by customer name, phone, or email',
      createQuoteButtonText: 'Create Quote',
      quoteId: 'Quote ID',
      customerName: 'Customer Name',
      status: 'Status',
      created: 'Created',
      createdAt: 'Created Date',
      updatedAt: 'Last Updated',
    },
    es: {
      title: 'Cola de cotizaciones',
      subtitle: 'Ver y administrar cotizaciones',
      search: 'Buscar',
      searchPlaceholder:
        'Buscar por nombre de cliente, teléfono o correo electrónico',
      createQuoteButtonText: 'Crear Cotización',
      quoteId: 'ID de Cotización',
      customerName: 'Nombre del Cliente',
      status: 'Estado',
      created: 'Creada',
      createdAt: 'Fecha de Creación',
      updatedAt: 'Last Updated',
    },
  },
  QuoteBuilder: {
    en: {
      title: 'Build a Quote',
      subtitle: 'Quickly compose a quote for an existing customer',
      partsListTitle: 'Parts List',
      changeCustomer: 'Edit Customer',
      customerInfo: 'Customer Information',
      resetQuote: 'Reset Quote',
      addPart: 'Add Line Item',
      saveQuote: 'Save Quote',
      selectCustomer: 'Select Customer',
      primaryContact: 'Primary Contact',
      companyAddress: 'Company Address',
      salesRepresentative: 'Sales Representative',
    },
    es: {
      title: 'Crear una cotización',
      subtitle: 'Componer rápidamente una cotización para un cliente existente',
      partsListTitle: 'Lista de partes',
      changeCustomer: 'Editar Cliente',
      customerInfo: 'Información del Cliente',
      resetQuote: 'Reiniciar Cotización',
      addPart: 'Añadir artículo de línea',
      saveQuote: 'Guardar Cotización',
      selectCustomer: 'Seleccionar Cliente',
      primaryContact: 'Contacto Principal',
      companyAddress: 'Detalles de la empresa',
      salesRepresentative: '',
    },
  },
  QuoteLineItem: {
    en: {
      qtyBoundsValidation:
        'Quantity must be within +- 10% of the previous order',
    },
  },
} as const satisfies Ii18nSection;

export default i18n;
