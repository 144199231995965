import type { ConfigurationFieldSchema } from '@ui/features/configurator/types';
import {
  contributionCostField,
  contributionPercentField,
  fixedScrapField,
  grossCostField,
  notesField,
  scrapYieldPercentageField,
  scrapYieldType,
} from './operations';

// Field IDs
const FIELD_IDS = {
  operation_type: 'operation_type',
  resource: 'resource',
  setup_hours: 'setup_hours',
  run: 'run',
  run_type: 'run_type',
  setup_cost_per_hour: 'setup_cost_per_hour',
  setup_burden_per_hour: 'setup_burden_per_hour',
  run_cost_per_hour: 'run_cost_per_hour',
  run_burden_per_hour: 'run_burden_per_hour',
  estimated_labor_cost: 'estimated_labor_cost',
  estimated_material_cost: 'estimated_material_cost',
  estimated_burden_cost: 'estimated_burden_cost',
} as const;

export const INTERNAL_OPERATION_FIELD_IDS = FIELD_IDS;

// Fields
const operationTypeField = {
  name: 'Operation Type',
  field_id: FIELD_IDS.operation_type,
  input_type: 'operation',
  field_level: 'operation',
  input_source: {
    source_type: 'dynamic',
    source_key: 'operation_type',
  },
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const resourceField = {
  name: 'Resource',
  field_id: FIELD_IDS.resource,
  input_type: 'single_choice',
  field_level: 'operation',
  input_source: {
    source_type: 'dynamic',
    source_key: 'resource',
  },
  hide_field: true,
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const setupHoursField = {
  name: 'Setup Hours',
  field_id: FIELD_IDS.setup_hours,
  input_type: 'number',
  field_level: 'operation',
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.resource,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const runField = {
  name: 'Run',
  field_id: FIELD_IDS.run,
  input_type: 'number',
  field_level: 'operation',
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.resource,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const runTypeField = {
  name: 'Run Type',
  field_id: FIELD_IDS.run_type,
  input_type: 'single_choice',
  field_level: 'operation',
  input_source: {
    source_type: 'static',
    data_key: 'run_type',
  },
  validations: [
    {
      validate: 'required',
    },
    {
      validate: 'function',
      name: 'runTypeIsPcsPerHr',
    },
  ],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.resource,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const scrapYieldTypeFieldWithDependencies = {
  ...scrapYieldType,
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.resource,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

export const setupCostPerHourField = {
  name: 'Setup Cost / Hour',
  field_id: FIELD_IDS.setup_cost_per_hour,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.resource,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const setupBurdenPerHourField = {
  name: 'Setup Burden / Hour',
  field_id: FIELD_IDS.setup_burden_per_hour,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.resource,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const runCostPerHourField = {
  name: 'Run Cost / Hour',
  field_id: FIELD_IDS.run_cost_per_hour,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.resource,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const runBurdenPerHourField = {
  name: 'Run Burden / Hour',
  field_id: FIELD_IDS.run_burden_per_hour,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.resource,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const estimatedLaborCostField = {
  name: 'Estimated Labor Cost',
  field_id: FIELD_IDS.estimated_labor_cost,
  input_type: 'number',
  field_level: 'summary',
  hide_field: true,
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const estimatedMaterialCostField = {
  name: 'Estimated Material Cost',
  field_id: FIELD_IDS.estimated_material_cost,
  input_type: 'number',
  field_level: 'summary',
  hide_field: true,
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const estimatedBurdenCostField = {
  name: 'Estimated Burden Cost',
  field_id: FIELD_IDS.estimated_burden_cost,
  input_type: 'number',
  field_level: 'summary',
  hide_field: true,
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

export const defaultInternalOperationFields = [
  operationTypeField,
  resourceField,
  setupHoursField,
  runField,
  runTypeField,
  scrapYieldTypeFieldWithDependencies,
  scrapYieldPercentageField,
  fixedScrapField,
  setupCostPerHourField,
  setupBurdenPerHourField,
  runCostPerHourField,
  runBurdenPerHourField,
  notesField,
  estimatedLaborCostField,
  estimatedMaterialCostField,
  estimatedBurdenCostField,
  contributionCostField,
  grossCostField,
  contributionPercentField,
] satisfies ConfigurationFieldSchema[];
