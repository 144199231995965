import type { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import type { PaginationParams } from '@lib/models';
import { configuratorServiceSchema } from '@lib/validation';
import type {
  ConfiguratorDynamicFieldDefinition,
  ConfiguratorFieldType,
  ConfiguratorSingleChoiceValue,
} from '@ui/features/configurator/types';
import { configuratorService } from '@ui/services';

export type ConfiguratorServiceOptionMeta = {
  id: string;
  description: string | null;
  vendorId: string | null;
  run: number | null;
  runType: VisOpRunRateType | null;
};

export const serviceFieldDefinition: ConfiguratorDynamicFieldDefinition<ConfiguratorServiceOptionMeta> =
  {
    endpoints: {
      list: '/configurator/services',
    },

    mapOption: (data) => {
      const service = configuratorServiceSchema.parse(data);

      return {
        label: service.id,
        value: service.id,
        description: service.description,
        meta: {
          description: service.description,
          id: service.id,
          run: service.run,
          runType: service.run_type,
          vendorId: service.vendor_id,
        },
      };
    },

    mapOptions: (resources) => {
      return resources.map(serviceFieldDefinition.mapOption);
    },

    fetchOne: () => {
      throw new Error('Not implemented');
    },

    search: ({
      query,
      pagination,
    }: { query: string; pagination: PaginationParams }) => {
      return configuratorService.getFieldOptions({
        fieldDefinition: serviceFieldDefinition,
        query,
        pagination,
      });
    },
  };

export function getServiceFieldValue(field: ConfiguratorFieldType) {
  if (field.inputType !== 'single_choice') {
    throw new Error(`Invalid service field with key ${field.fieldKey}`);
  }

  return field.value as ConfiguratorSingleChoiceValue<ConfiguratorServiceOptionMeta>;
}
