import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Show } from '@ui/components/Show';
import { memo } from 'react';
import { useConfiguratorStore } from '../../hooks/use-configurator-store';
import type { FieldCalculatorComponentType } from '../../store/helpers/field-calculators';
import type { ConfiguratorInputProps } from './types';

type ConfiguratorInputWrapperComponentProps = ConfiguratorInputProps & {
  children: React.ReactNode;
  hideLabel?: boolean;
  hideError?: boolean;
  calculatorComponent?: FieldCalculatorComponentType;
};

function InputWrapperComponent({
  fieldKey,
  children,
  hideLabel,
  hideError,
  minWidth = '200px',
  maxWidth = '200px',
}: ConfiguratorInputWrapperComponentProps) {
  const { fields } = useConfiguratorStore();

  const field = fields[fieldKey];

  const CalculatorComponent = field.calculatorComponent;

  if (field.isHidden || field.isAlwaysHidden) {
    return null;
  }

  return (
    <FormControl
      id={fieldKey}
      isInvalid={field.isInvalid}
      isDisabled={field.isDisabled}
      isRequired={field.isRequired}
      // Style Props
      display="flex"
      flexDirection="column"
      gap={1}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      <Show when={field.name && !hideLabel}>
        <FormLabel fontSize="sm" fontWeight="medium" margin={0}>
          {field.description ? (
            <Tooltip label={field.description} placement="end">
              <Text
                textDecoration="underline"
                textDecorationStyle="dashed"
                textDecorationColor="mw.lightGrey"
                display="inline"
              >
                {field.name}
              </Text>
            </Tooltip>
          ) : (
            field.name
          )}
        </FormLabel>
      </Show>

      <Flex gap={1} alignItems="center">
        {children}
        {CalculatorComponent ? <CalculatorComponent field={field} /> : null}
      </Flex>

      <Show when={field.errors.length > 0 && !hideError}>
        <FormErrorMessage pl={4}>
          <ul>
            {field.errors.map((error, i) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </FormErrorMessage>
      </Show>
    </FormControl>
  );
}

/**
 * Wrapper for Configurator input components, handling errors, labels, and visibility.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorInputWrapper = memo(InputWrapperComponent);
