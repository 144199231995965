import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import { loginRequest } from '../../config/authConfig';
import { EnvConfig } from '../../config/env';
import { useAuth } from '../../state/auth';
import IdleLogout from '../IdleLogout';
import Loading from '../Loading';
import WithUser from '../WithUser';
import { AuthErrorMessage } from './AuthError';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const authRequest = { ...loginRequest };
  const auth = useAuth();
  if (!auth) {
    return <Navigate to="/logout/redirect" />;
  }

  const { isLoggedOut, logout, user } = auth;

  const timeoutSeconds = Number.parseInt(
    EnvConfig.getKey('IDLE_TIMOUT_SECONDS') ?? '1800',
  );
  const promptBeforeSeconds = Number.parseInt(
    EnvConfig.getKey('IDLE_TIMEOUT_PROMPT_BEFORE_SECONDS') ?? '120',
  );

  return isLoggedOut ? (
    <Navigate to="/logout/redirect" />
  ) : (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={AuthErrorMessage}
      loadingComponent={AuthLoading}
    >
      <IdleLogout {...{ user, logout, timeoutSeconds, promptBeforeSeconds }} />
      <WithUser>{children}</WithUser>
    </MsalAuthenticationTemplate>
  );
}

function AuthLoading() {
  return <Loading />;
}

export default ProtectedRoute;
