import type {
  ConfiguratorValueType,
  WritableConfiguratorStoreState,
} from '@ui/features/configurator/types';
import type { ConfiguratorAction } from './types';
import { updateFieldAction } from './update-field';

export type FieldUpdate = { fieldKey: string; value: ConfiguratorValueType };

export type UpdateFieldsAction = ConfiguratorAction<
  'updateFields',
  FieldUpdate[]
>;

export const updateFields = (
  fieldUpdates: FieldUpdate[],
): UpdateFieldsAction => ({
  type: 'updateFields',
  payload: fieldUpdates,
});

/**
 * Update multiple step's values, runs validations, and updates the store metadata as needed.
 */
export function updateFieldsAction(
  state: WritableConfiguratorStoreState,
  fieldUpdates: FieldUpdate[],
) {
  for (const update of fieldUpdates) {
    updateFieldAction(state, update.fieldKey, update.value);
  }
}
