import { MimeTypes } from '@lib/enums/mime-types';
import type { PaginationParams } from '@lib/models';
import {
  QUOTE_SORT_FIELDS,
  type QuoteCreateRequest,
  type QuoteFeedbackRequest,
  type QuoteSearchQuery,
  type QuoteSortQuery,
  type QuoteSortRequestFields,
  type QuoteUpdateRequest,
} from '@lib/requests/quote';
import type {
  QuoteDraftResponse,
  QuoteFeedbackReasonsResponse,
  QuoteFeedbackResponse,
  QuoteGetManyResponse,
  QuoteLineDeleteResponse,
  QuoteLineDocumentCreateResponse,
  QuoteResponse,
  QuoteSentToCustomerResponse,
} from '@lib/responses/quote';
import { isInSet } from '@lib/util/type-guards';
import type { SortingState } from '@tanstack/react-table';
import type { QuoteBuilderForm } from '@ui/pages/QuoteBuilder/QuoteBuilder';
import type { API } from './api';

export class QuoteService {
  constructor(private api: API) {}

  createQuote = (data: QuoteCreateRequest) => {
    return this.api.post<QuoteDraftResponse>('/quote', data);
  };

  /**
   *
   * @param quoteId uuid key
   * @returns
   */
  getQuote = (quoteId: string) => {
    return this.api.get<QuoteResponse>(`/quote/${quoteId}`);
  };

  /**
   * @param id uuid key
   * @param data
   */
  updateQuote = (id: string, data: QuoteUpdateRequest) => {
    return this.api.put<QuoteResponse>(`/quote/${id}`, data);
  };

  /**
   *
   * @param quoteId uuid key
   * @param lineItemId uuid key
   * @returns
   */
  createQuoteLineDocument = (
    quoteId: string,
    lineItemId: string,
    document: File,
  ) => {
    const formData = new FormData();
    formData.append('document', document);

    return this.api.postFile<QuoteLineDocumentCreateResponse>(
      `/quote/${quoteId}/${lineItemId}/document`,
      formData,
    );
  };

  /**
   *
   * @param quoteId uuid key
   * @param lineItemId uuid key
   * @returns
   */
  deleteQuoteLineItem = (quoteId: string, lineItemId: string) => {
    return this.api.delete<QuoteLineDeleteResponse>(
      `/quote/${quoteId}/${lineItemId}`,
    );
  };

  /**
   *
   * @param quoteId uuid key
   * @param lineItemId uuid key
   * @param qtyBreakId uuid key
   * @returns
   */
  deleteQuoteLineItemQuantityBreak = (
    quoteId: string,
    lineItemId: string,
    qtyBreakId: string,
  ) => {
    return this.api.delete<{ ok: true }>(
      `/quote/${quoteId}/${lineItemId}/qtyBreak/${qtyBreakId}`,
    );
  };

  /**
   * @param search
   * @param pagination.page - start page
   * @param pagination.perPage - results per page
   * @param sort - array of sort fields by priority {id:field,desc:bool}[]
   * @memberof QuoteService
   */
  searchQuotes = (
    search: string,
    { page, perPage }: PaginationParams,
    sort: SortingState = [],
  ) => {
    const query: QuoteSearchQuery = {
      search,
      page,
      perPage,
      sort: this.serializeSort(sort),
    };
    return this.api.get<QuoteGetManyResponse>('/quote/search', query);
  };

  sendQuote = (
    quoteId: string,
    pdf: Blob,
    sendToCustomer: boolean,
    quoteBuilderForm: QuoteBuilderForm,
  ) => {
    const formData = new FormData();
    const quotepdf = new File([pdf], 'quote.pdf', { type: MimeTypes.pdf });
    formData.append('sendToCustomer', `${sendToCustomer}`);
    formData.append(
      'daysUntilExpiration',
      `${quoteBuilderForm.daysUntilExpiration}`,
    );
    formData.append(
      'daysUntilExpectedWin',
      `${quoteBuilderForm.daysUntilExpectedWin}`,
    );
    formData.append('quotePdfSize', `${pdf.size}`); // size not transferred automatically and needed for s3 upload
    formData.append('quotePdf', quotepdf, 'quote.pdf'); // important file goes last so other fields are parsed first on backend
    return this.api.postFile<QuoteSentToCustomerResponse>(
      `/quote/${quoteId}/send`,
      formData,
    );
  };

  getQuoteFeedback = (quoteId: string) => {
    return this.api.get<QuoteFeedbackResponse>(`/quote/${quoteId}/feedback`);
  };

  getQuoteFeedbackReasons = () => {
    return this.api.get<QuoteFeedbackReasonsResponse>(
      '/quote-feedback/feedback-reasons',
    );
  };

  sendQuoteFeedback = (quoteId: string, updates: QuoteFeedbackRequest) => {
    return this.api.put(`/quote/${quoteId}/feedback`, updates);
  };

  /**
   *
   * @param quoteId  uuid key
   * @returns
   */
  deleteQuote = (quoteId: string) => {
    return this.api.delete(`/quote/${quoteId}`);
  };

  getQuotePDF = async (quoteId: string): Promise<string> => {
    const resp = await this.api.get(`/quote/${quoteId}/document`);

    return resp;
  };

  private serializeSort(sort: SortingState): QuoteSortQuery[] {
    // we only want to include valid fields
    const serializedSort = sort?.reduce((acc, cur) => {
      const { id, desc } = cur;
      let field: QuoteSortRequestFields | undefined;

      // flatten nested
      if (id === 'customer.name') {
        field = 'customerName';
      } else if (isInSet(QUOTE_SORT_FIELDS, id)) {
        field = id;
      }

      field && acc.push(`${field}:${desc ? 'desc' : 'asc'}`);
      return acc;
    }, [] as QuoteSortQuery[]);
    return serializedSort;
  }
}
