import type { ConfigurationFieldSchema } from '@ui/features/configurator/types';

// Field IDs
const FIELD_IDS = {
  notes: 'notes',
  scrap_yield_type: 'scrap_yield_type',
  scrap_yield_percentage: 'scrap_yield_percentage',
  fixed_scrap: 'fixed_scrap',
  contribution_cost: 'contribution_cost',
  contribution_percent: 'contribution_percent',
  gross_cost: 'gross_cost',
} as const;

export const OPERATION_FIELD_IDS = FIELD_IDS;

// Fields
export const notesField = {
  name: 'Notes',
  placeholder: 'Add a note for this operation',
  field_id: FIELD_IDS.notes,
  field_level: 'note',
  input_type: 'textarea',
  validations: [],
  dependencies: [],
  dependency_mode: null,
  default_value: '',
} satisfies ConfigurationFieldSchema;

export const scrapYieldType = {
  name: 'Scrap Yield Type',
  field_id: FIELD_IDS.scrap_yield_type,
  input_type: 'single_choice',
  field_level: 'operation',
  input_source: {
    source_type: 'static',
    data_key: 'scrap_yield_type',
  },
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

export const scrapYieldPercentageField = {
  name: 'Scrap/Yield %',
  field_id: FIELD_IDS.scrap_yield_percentage,
  right_icon: 'percent',
  input_type: 'number',
  field_level: 'operation',
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.scrap_yield_type,
      dependency_type: 'selected_value',
      selected_value_options: ['S', 'Y'],
    },
  ],
  dependency_mode: 'show',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

export const fixedScrapField = {
  name: 'Fixed Scrap',
  field_id: FIELD_IDS.fixed_scrap,
  input_type: 'number',
  field_level: 'operation',
  validations: [
    {
      validate: 'required', // technically not required unless `scrap_yield_type` is "Scrap", not "Yield"
    },
  ],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.scrap_yield_type,
      dependency_type: 'selected_value',
      selected_value_options: ['S'],
    },
  ],
  dependency_mode: 'show',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

export const contributionCostField = {
  name: 'Contribution Cost',
  field_id: FIELD_IDS.contribution_cost,
  input_type: 'number',
  field_level: 'summary',
  hide_field: true,
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

export const contributionPercentField = {
  name: 'Percent',
  field_id: FIELD_IDS.contribution_percent,
  input_type: 'calculated_number',
  field_level: 'summary',
  right_icon: 'percent',
  validations: [],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

export const grossCostField = {
  name: 'Gross Cost',
  field_id: FIELD_IDS.gross_cost,
  input_type: 'calculated_number',
  field_level: 'summary',
  left_icon: 'dollar',
  validations: [],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;
