import { SCRAP_YIELD_NONE_OPTION } from '@ui/features/configurator/data/constants/input-sources';
import type {
  ConfigurationFieldSchema,
  ConfigurationStepSchema,
} from '@ui/features/configurator/types';
import {
  contributionCostField,
  contributionPercentField,
  fixedScrapField,
  grossCostField,
  notesField,
  scrapYieldPercentageField,
  scrapYieldType,
} from './operations';

// Step Keys
const STEP_KEYS = {
  outside_service: 'outside_service',
} as const;

export const EXTERNAL_OPERATION_STEP_KEYS = STEP_KEYS;

// Field IDs
const FIELD_IDS = {
  service: 'service',
  vendor: 'vendor',
  transport_days: 'transport_days',
  base_charge: 'base_charge',
  minimum_charge: 'minimum_charge',
  price_per_unit: 'price_per_unit',
  estimated_service_cost: 'estimated_service_cost',
} as const;

export const EXTERNAL_OPERATION_FIELD_IDS = FIELD_IDS;

// Fields
const serviceField = {
  name: 'Service',
  field_id: FIELD_IDS.service,
  input_type: 'single_choice',
  field_level: 'operation',
  input_source: {
    source_type: 'dynamic',
    source_key: 'service',
  },
  validations: [{ validate: 'required' }],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const vendorField = {
  name: 'Vendor',
  field_id: FIELD_IDS.vendor,
  input_type: 'single_choice',
  field_level: 'operation',
  input_source: {
    source_type: 'dynamic',
    source_key: 'vendor',
  },
  validations: [{ validate: 'required' }],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const transportDaysField = {
  name: 'Transport Days',
  field_id: FIELD_IDS.transport_days,
  input_type: 'number',
  field_level: 'operation',
  validations: [],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const baseChargeField = {
  name: 'Base Charge',
  field_id: FIELD_IDS.base_charge,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const minimumChargeField = {
  name: 'Minimum Charge',
  field_id: FIELD_IDS.minimum_charge,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const pricePerUnitField = {
  name: 'Price Per Unit',
  field_id: FIELD_IDS.price_per_unit,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const estimatedServiceCostField = {
  name: 'Estimated Service Cost',
  field_id: FIELD_IDS.estimated_service_cost,
  input_type: 'number',
  field_level: 'summary',
  hide_field: true,
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const externalScrapYieldType = {
  ...scrapYieldType,
  default_value: SCRAP_YIELD_NONE_OPTION,
} satisfies ConfigurationFieldSchema;

// Steps
export const defaultExternalServiceStep = {
  id: 'AFE4DAF3-A81D-4D00-A960-4C8A62950510',
  step_key: STEP_KEYS.outside_service,
  name: 'Outside Service',
  step_type: 'operation',
  operation_type: 'external',
  required: false,
  reusable: true,
  fields: [
    serviceField,
    vendorField,
    transportDaysField,
    externalScrapYieldType,
    scrapYieldPercentageField,
    fixedScrapField,
    baseChargeField,
    minimumChargeField,
    pricePerUnitField,
    notesField,
    estimatedServiceCostField,
    contributionCostField,
    grossCostField,
    contributionPercentField,
  ],
} satisfies ConfigurationStepSchema;
